import $ from 'jquery'

if ($.fn.modal) {
  // Extends the default hide event to trigger another 'hide-with-trigger-event' & pass the original trigger event
  const existingHide = $.fn.modal.Constructor.prototype.hide
  $.fn.modal.Constructor.prototype.hide = function (e) {
    const ev = $.Event('hide-with-trigger-event.bs.modal', { triggerEvent: e })
    this.$element.trigger(ev)
    if (ev.isDefaultPrevented && ev.isDefaultPrevented()) return
    existingHide.apply(this, arguments)
  }

  // Extend the default escape event to trigger hide() with the original keydown event
  //  i.e add `e` as the param to the `this.hide()` call below
  $.fn.modal.Constructor.prototype.escape = function () {
    if (this.isShown && this.options.keyboard) {
      this.$element.on(
        'keydown.dismiss.bs.modal',
        $.proxy(function (e) {
          e.which == 27 && this.hide(e)
        }, this)
      )
    } else if (!this.isShown) {
      this.$element.off('keydown.dismiss.bs.modal')
    }
  }

  $.fn.modal.Constructor.prototype.setScrollbar = function () {
    // Bootstrap adds paddingRight when opening/hiding modals to try to prevent content shift.
    // But looks like for us it does the opposite (on chrome+arc atleast). It also doesn't work when multiple modals are involved.
  }
}
