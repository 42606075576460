/**
 * This script applies only to embedded surveys
 *
 * it is needed because there is no fixed height that will fit any survey
 * when it has conditional fields, they will dynamically extend/contract the page height
 *
 * Since the iframe will be most likely CORS, there's no way for the parent to just grab the document height
 * So to achieve this we need scripts running on the page embedding the iframe, which you can look up on the Survey model
 * and the second piece runs inside the iframe, sending messages to the parent window, with information about
 * the document height.
 *
 * Now, we need to be careful that there could be other scripts/iframes sending messages, so to prevent any weird
 * errors, we scope the message.
 *
 * The script runs on 2 events:
 * 1. On page load, to get the document height the moment the user loads the survey
 * 2. Whenever changes happen on the page, via a Mutation Observer
 */

if (
  window.self !== window.top &&
  document.querySelector('#new_survey_response')
) {
  window.addEventListener('load', function () {
    let message = { simplero_iframe: { height: document.body.scrollHeight } }

    window.top.postMessage(message, '*')
  })
  if (window.MutationObserver) {
    const config = { attributes: true, childList: true, subtree: true }
    const callback = () => {
      let message = { simplero_iframe: { height: document.body.scrollHeight } }

      window.top.postMessage(message, '*')
    }
    const observer = new MutationObserver(callback)
    observer.observe(document.body, config)
  }
}
