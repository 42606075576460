import $ from 'jquery'

$.fn.formToggle = function (show) {
  return this.each(function () {
    const $this = $(this)
    if (show === null || show === undefined) {
      show = !$this.is(':visible')
    }
    if (window.js_dom_ready) {
      if (show) {
        $this.slideDown('fast')
      } else {
        $this.slideUp('fast')
      }
    } else {
      if (show) {
        $this.show()
      } else {
        $this.hide()
      }
    }
  })
}

$.fn.showInputs = function (animate) {
  return this.each(function () {
    let $this = $(this)
    if (animate && window.js_dom_ready) {
      $this.filter('span').show()
      $this.filter(':not(span)').slideDown('fast')
    } else {
      $this.show()
    }
    $('input,select,textarea', this)
      .addBack()
      .each(function () {
        $this = $(this)
        if (!this.closest('.js-no-change-on-showInputs')) {
          // Triggering a change event is a somewhat expensive thing to do, if you're doing it a lot.
          // Each trigger invocation takes ~4ms. If you've got 100-200 inputs on which something is calling showInputs on, that's going to be slow.
          // I am not sure why this was added. The commit says it was to cascade the event to dependent selects.
          // But I am unsure if there are other use-cases since then that is relying on this behavior.
          // So there's an escape hatch to disable this triggering of change event for extreme cases like builder where we've got a ton of inputs which are toggled using showInputs (e.g through toggle_prefix).
          // Just make sure there's parent with the class 'js-no-change-on-showInputs' and we won't trigger a change event here.
          $this.trigger('change')
        }
        if ($this.data('we-disabled')) {
          $this.prop('disabled', false)
        }
        if (
          $this.hasClass('tinymce-admin') ||
          $this.hasClass('tinymce-regular')
        ) {
          applyNewStyles($this)
        }

        const prosemirrorEditor =
          window.proseMirror?.editors?.[$this.attr('id')]
        if (prosemirrorEditor) {
          prosemirrorEditor.prosemirrorElements.addInheritedStyles()
        }

        if ($this.data('behavior') === 'selectize' && $this[0].selectize) {
          $this[0].selectize.enable()
        }
        if (
          $this.hasClass('selectized-with-search-box') &&
          $this[0].selectize
        ) {
          $this[0].selectize.$wrapper.show()
        }
        if ($this.data('editor')) {
          $this.data('editor').setMode('design')
        }
      })
  })
}

$.fn.hideInputs = function (animate) {
  return this.each(function () {
    let $this = $(this)
    if (animate && window.js_dom_ready) {
      $this.filter('span').hide()
      $this.filter(':not(span)').slideUp('fast')
    } else {
      $this.hide()
    }
    $('input,select,textarea', this)
      .addBack()
      .each(function () {
        $this = $(this)
        if (!$this.prop('disabled') && !$this.data('disableDisallowed')) {
          $this.prop('disabled', true).data('we-disabled', true)
        }
        if ($this.data('behavior') === 'selectize' && $this[0].selectize) {
          $this[0].selectize.disable()
        }
        if (
          $this.hasClass('selectized-with-search-box') &&
          $this[0].selectize
        ) {
          $this[0].selectize.$wrapper.hide()
        }
        if ($this.data('editor')) {
          $this.data('editor').setMode('readonly')
        }
      })
  })
}

function applyNewStyles(elm) {
  const editor = window.tinyMCE.get(elm.attr('id'))
  if (editor) {
    if (!editor.contentWindow) {
      return setTimeout(() => applyNewStyles(elm), 100)
    }
    const inputEl = $(editor.targetElm)
    // Inherit styles from the interface.
    const inheritedStyles = []
    const object = inputEl.css([
      'background-color',
      'color',
      'cursor',
      'font-family',
      'font-size',
      'font-style',
      'font-variant',
      'font-weight',
      'line-height',
      'letter-spacing',
      'text-align',
      'text-decoration',
      'text-indent',
      'text-rendering',
      'word-break',
      'word-wrap',
      'word-spacing',
    ])
    for (let k in object) {
      const v = object[k]
      inheritedStyles.push(`${k}: ${v}`)
    }
    const styles = `body.wysiwyg-inherited { ${(inheritedStyles || []).join(
      '; '
    )} }`
    const css = document.createElement('style')
    css.type = 'text/css'
    if (css.styleSheet) {
      css.styleSheet.cssText = styles
    } else {
      css.appendChild(document.createTextNode(styles))
    }
    editor.contentWindow.document.head.appendChild(css)
  }
}
