//##################################
// Checkbox toggle behaviors
//   - data-checkbox-toggle
//        For when you want to conditionally show an element based on if checkbox is checked
//   - data-checkbox-toggle-inverse
//        Same as above, but shows when the checkbox is not checked, and hides when checked
//   - data-checkbox-toggle-disabled
//        For when you want to toggle the disabled attribute of some other element based on if checkbox is checked

if (window.onDomChanged) {
  onDomChanged('input[type=checkbox][data-checkbox-toggle]', function () {
    return this.change(function () {
      // We use attr here and not data, because data will try to interpret the value as JSON
      return $($(this).attr('data-checkbox-toggle')).formToggle(
        $(this).is(':checked')
      )
    }).change()
  })

  onDomChanged(
    'input[type=checkbox][data-checkbox-toggle-inverse]',
    function () {
      return this.change(function () {
        // We use attr here and not data, because data will try to interpret the value as JSON
        return $($(this).attr('data-checkbox-toggle-inverse')).formToggle(
          !$(this).is(':checked')
        )
      }).change()
    }
  )

  // Disables when checkbox is unchecked
  onDomChanged(
    'input[type=checkbox][data-checkbox-toggle-disabled]',
    function () {
      return this.change(function () {
        // We use attr here and not data, because data will try to interpret the value as JSON
        return $($(this).attr('data-checkbox-toggle-disabled')).attr(
          'disabled',
          !$(this).prop('checked')
        )
      }).change()
    }
  )

  // Disables when checkbox is checked
  onDomChanged(
    'input[type=checkbox][data-checkbox-toggle-disabled-inverse]',
    function () {
      return this.change(function () {
        // We use attr here and not data, because data will try to interpret the value as JSON
        return $($(this).attr('data-checkbox-toggle-disabled-inverse')).attr(
          'disabled',
          $(this).prop('checked')
        )
      }).change()
    }
  )

  onDomChanged('input[type=checkbox][data-checkbox-toggle-force]', function () {
    return this.change(function () {
      if ($(this).prop('checked')) {
        $($(this).attr('data-checkbox-toggle-force')).prop('checked', true)
      }
      // We use attr here and not data, because data will try to interpret the value as JSON
      return $($(this).attr('data-checkbox-toggle-force')).attr(
        'disabled',
        $(this).prop('checked')
      )
    }).change()
  })
} else {
  console.error('onDomChanged not defined')
}
